import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				PREMIER LEGAL SOLUTIONS
			</title>
			<meta name={"description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:title"} content={"PREMIER LEGAL SOLUTIONS"} />
			<meta property={"og:description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Premier Legal Solutions
			</Override>
			<Override slot="quarklycommunityKitMobileSidePanel" onloadShow={false} />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-padding="0px 150px 0px 0px"
					md-padding="0px 0 0px 0px"
					align-items="center"
					display="flex"
					flex-direction="column"
					justify-content="center"
					flex-wrap="no-wrap"
					text-align="left"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Bugün Bize Ulaşın
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						Özel hukuk profesyonellerinden oluşan bir ekiple çalışmaya hazır mısınız? Danışmanlık almak için bize ulaşın ve hukuki zorluklarınızda uzmanlığımız ve şefkatimizle size yol göstermemize izin verin.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07:17:17.926Z"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
					srcSet="https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab35c82fc7070018ba7c91/images/2-1.jpg?v=2024-08-01T07%3A17%3A17.926Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				padding="40px 40px 60px 40px"
				background="--color-darkL2"
				border-radius="24px"
				max-width="940px"
				width="100%"
				lg-max-width="480px"
				lg-padding="40px 40px 40px 40px"
				align-items="center"
				display="flex"
				flex-direction="row"
				justify-content="center"
				margin="2rem auto 0px auto"
			>
				<Components.QuarklycommunityKitNetlifyForm>
					<Override
						slot="Form"
						flex-direction="row"
						display="flex"
						width="100%"
						align-items="flex-end"
						justify-content="center"
					/>
					<Box
						align-items="flex-end"
						md-align-items="center"
						display="flex"
						justify-content="center"
						lg-flex-wrap="wrap"
						lg-justify-content="flex-end"
					>
						<Box
							margin="0px 30px 0px 0px"
							md-width="100%"
							display="flex"
							flex-direction="column"
							sm-margin="0px 0px 0px 0px"
							lg-margin="0px 0px 30px 0px"
							md-margin="0px 0px 30px 0px"
							flex="1 1 auto"
							width="100%"
						>
							<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
								İsim
							</Text>
							<Input
								border-radius="8px"
								padding="11px 16px 11px 16px"
								required={false}
								font="--lead"
								color="rgba(111, 131, 159, 0.5)"
								border-width="0px"
								placeholder-color="rgba(111, 131, 159, 0.5)"
								placeholder="Mahir"
								name="Name"
								type="text"
								sm-width="100%"
								lg-width="100%"
								lg-margin="0px 0px 10px 0px"
								flex="1 1 auto"
								width="100%"
								margin="0px 0px 0px 0px"
							/>
						</Box>
						<Box
							margin="0px 30px 0px 0px"
							sm-margin="0px 0px 30px 0px"
							lg-margin="0px 0px 30px 0px"
							md-width="100%"
							flex="1 1 auto"
							width="100%"
							display="flex"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
								E-posta
							</Text>
							<Input
								color="rgba(111, 131, 159, 0.5)"
								lg-margin="0px 0px 10px 0px"
								border-width="0px"
								placeholder="name@mail.com"
								flex="1 1 auto"
								name="Email"
								required={false}
								width="100%"
								padding="11px 16px 11px 16px"
								type="email"
								lg-width="100%"
								placeholder-color="rgba(111, 131, 159, 0.5)"
								margin="0px 0px 0px 0px"
								font="--lead"
								sm-width="100%"
								border-radius="8px"
							/>
						</Box>
						<Box
							margin="0px 30px 0px 0px"
							sm-margin="0px 0px 30px 0px"
							lg-margin="0px 0px 30px 0px"
							md-width="100%"
							flex="1 1 auto"
							width="100%"
							display="flex"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
								Telefon numarası
							</Text>
							<Input
								color="rgba(111, 131, 159, 0.5)"
								lg-margin="0px 0px 10px 0px"
								border-width="0px"
								placeholder="+90 (5__) ___ __ __"
								flex="1 1 auto"
								name="Tel"
								required={false}
								width="100%"
								padding="11px 16px 11px 16px"
								type="tel"
								lg-width="100%"
								placeholder-color="rgba(111, 131, 159, 0.5)"
								margin="0px 0px 0px 0px"
								font="--lead"
								sm-width="100%"
								border-radius="8px"
							/>
						</Box>
						<Button
							font="--lead"
							padding="12px 64px 12px 64px"
							background="--color-dark"
							border-radius="8px"
							sm-display="block"
							sm-width="100%"
						>
							Gönder
						</Button>
					</Box>
				</Components.QuarklycommunityKitNetlifyForm>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text1" />
		</Components.Contacts>
		<Components.Newfooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});